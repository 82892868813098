export const environment = {
	baseUrl: "https://api.sportyano.com/api/",
	firebaseConfig: {
		apiKey: "AIzaSyBYFpNiEFJWJmi_nj6DIJOPCYOvza_-GHw",
		authDomain: "sportyano-bbfa2.firebaseapp.com",
		projectId: "sportyano-bbfa2",
		storageBucket: "sportyano-bbfa2.appspot.com",
		messagingSenderId: "309823486163",
		appId: "1:309823486163:web:d45f71b4da85bd41d7fc26",
		measurementId: "G-YEFHDQFHX4",
	},
	recaptcha_site_key:"6LczjXoqAAAAAIyGnxtIwAcR5iNOtk7PAp-4FKO2",
	googleAnalyticsTrackingId: 'G-7PY5LMK2T9', // Add your Google Analytics tracking ID here
	version: require('../../package.json').version_prod
};
